<template>
  <div>
    <div class="selCard mb14">
      <el-form
        size="small"
        inline
        :model="searchData"
        ref="searchForm"
        label-width="85px"
      >
        <el-form-item label="规格名称">
          <el-input
            v-model="searchData.name"
            placeholder="输入规格名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="handleSearch()"
            >搜索</el-button
          >
          <el-button size="small" @click="handleReset()">重置</el-button>

          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="handleCreate"
            >新增规格</el-button
          >
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="exportSpec"
            >规格导入</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template slot="menu" slot-scope="{ row }">
        <el-button size="mini" type="text" icon="el-icon-edit" @click="handleEditSon(row)">子规格管理</el-button>
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
        <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
        :title="`${form.id ? '编辑' : '新增'}规格`"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 @submit="submit">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
    <Import
      v-if="importShow"
      :show="importShow"
      :action="importAction"
      :downloadLink="importDownloadLink"
      :problemLink="importProblem"
      @close="importClose"
    />
  </div>
</template>

<script>
// import {list, , add, view, update} from '@/api/shop/spec'
import {list,add,deleteSpec,view, update} from '@/api/supplierGoods/spec'
import {getIndex} from "@/utils/other";
import Import from "../../storeMember/comments/import";
export default {
  name: "index",
  components: {
    Import,
  },
  data(){
    return {
      importShow: false,
      importAction: "",
      importDownloadLink: "",
      importProblem: "",
      searchData: {
        name: '' 
      },
      page:{
        total: 0,
        currentPage: 1,
        pageSize:[20]
      },
      tableData: [],
      showLoading: false,
      option:{
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align:'center',
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
        {
            label: "序号",
            prop: "tabelIndex",
          },
          {
            label: '规格名称',
            prop: 'name'
          },
          {
            label: '子规格数量',
            prop: 'num'
          }
        ]
      },

      dialogVisible: false,
      form: {
        id: '',
        name: '',
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: '规格名称',
            prop: 'name',
            maxlength: 10,
            showWordLimit: true,
            span: 24,
            rules: [{
              required: true,
              message: "请输入规格名称",
              trigger: "blur"
            }]
          },
        ]
      }
    }
  },
  async mounted() {
  },
  methods: {

    exportSpec() {
      // console.log('批量导入房源')
      this.importAction = "/api/goods/web/spec/goods/spec/import";
      this.importDownloadLink = "/api/goods/web/spec/template/export";
      this.importProblem = "/api/goods/web/spec/error/export";
      this.importShow = true;
    },
    // 导入弹框关闭
    importClose(e) {
      this.importShow = false;
      if (e === 1 || e === 2) {
        this.onLoad();
      }
    },
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        this.tableData = res.data.records

        this.tableData.map((item,index)=>{
          item.tabelIndex = index+1
          })
        this.page.total = res.data.total
      })
    },
    // 新增规格
    handleCreate(){
      // console.log('新增规格')
      this.dialogVisible = true;
    },
    // 查询规格
    handleSearch(){
      this.page.currentPage = 1;
      this.onLoad()
    },
    // 重置规格
    handleReset(){
      this.searchData = {
        name: ''
      }
      this.page.currentPage = 1;
      this.onLoad()
    },
    // 子规格管理
    handleEditSon(row){
      console.log(row)
      this.$router.push({
        path: '/supplierGoods/spec/specSon',
        query: {
          id: row.id
        }
      })
    },
    // 编辑规格
    async handleEdit(row){
      console.log('编辑规格', row)
      const { data } = await view(row.id)
      this.form = data;
      this.dialogVisible = true;
    },
    // 删除规格
    handleDelete(row){
      // console.log('删除规格', row)
      this.$confirm('此操作将永久删除该规格, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteSpec(row.id).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功!');
            this.onLoad();
          }else{
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 新增/编辑规格
    submit(form,done){
      // console.log('新增/编辑规格', form)
      if(form.id){
        update(form).then(res => {
          if(res.code === 200){
            this.$message.success('修改成功')
            this.dialogVisible = false;
            setTimeout(() => {
              this.resetForm()
            }, 500)
            this.onLoad()
          }else{
            done()
          }
        }).catch(()=> done())
      }else{
        add(form).then(res => {
          if(res.code === 200){
            this.$message.success('新增成功')
            this.dialogVisible = false;
            setTimeout(() => {
              this.resetForm()
            }, 500)
            this.onLoad()
          }else{
            done()
          }
        }).catch(()=> done())
      }
    },
    // 关闭弹窗
    handleClose(){
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm()
      }, 500)
    },
    // 重置form
    resetForm(){
      this.form = {
        id: '',
        name: '',
        supplierId: ''
      }
    }
  }
}
</script>


<style lang="less" scoped>
/deep/.el-pagination__sizes{
  display: none !important;
}
</style>