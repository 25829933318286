import { get, put,Delete,post } from '../axios'

// 规格列表
export const list = params => get(`/goods/web/spec/list`,params);
// 规格新增
export const add = params => post(`/goods/web/spec/add`,params);
// 删除规格
export const deleteSpec = params => Delete(`/goods/web/spec/delete/${params}`);
// 查看规格
export const view = params => get(`/goods/web/spec/view/${params}`);
// 编辑规格
export const update = params => put(`/goods/web/spec/update`,params);
 

